.Parts_Dash_Main_Container {
    & .ScrollArrow-Container {
        padding-top: 25px;
        height: 100%;
    }
    .btn-container {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        & .MuiButton-label {
            white-space: nowrap;
        }
    }
    .btn-container::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    .pieChartSize {
        width: 100%;
        height: 200px;
    }
    .dashHeading {
        // text-align: left !important;
        // font-weight: 650;
        margin-left: 20px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        letter-spacing: '1px';

        // font-weight: 500;
        font-weight: 600 !important;
        line-height: 1.5;
        font-family: Inter, sans-serif;
        // display: block;
    }
}
