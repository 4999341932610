.days-past-due-date-in-5 {
    background: yellow;
}

.days-past-due-date-in-40 {
    background: orange;
}

.has-return-reason {
    background: #ff4500b8;
    color: white;
}
.Deleted-record {
    background: rgb(240, 238, 238);
}

.left-align-buttons {
    margin-bottom: 0px !important;
    padding-bottom: 2px;
    & .MuiButton-root {
        min-width: auto !important;
    }
    & > button + button {
        margin-left: 10px;
    }
}

.grid-filter {
    width: 400px;
    margin-left: 10px;
    position: relative;

    & > label {
        right: 10px;
        position: absolute;
        top: 15px;
        z-index: -99;
        color: lightgray;
    }
    @media (max-width: 767px) {
        margin-left: 5px;
    }
}

.internalgridFont {
    cursor: pointer;
    @media (max-width: 767px) {
        font-size: 24px !important;
    }
    @media (max-width: 600px) {
        font-size: 28px !important;
    }
}

.gridHeader {
    font-size: 40px;
    font-weight: bold !important;
    background-color: azure;
}

.gridDowloadBtn {
    position: relative;
    @media (min-width: 1100px) {
        position: absolute;
        top: -38px;
        right: 10px;
        z-index: 99;
    }
}

.filter-Container {
    position: relative;
    .filter-div {
        // position: absolute;
        // top: -10px;
        // right: 0px;
        @media (max-width: 900px) {
            position: relative;
            top: 0;
            left: 0;
            text-align: left;
        }
    }
}
