.days-past-due-date-in-5 {
    background:yellow;
}

.days-past-due-date-in-40{
    background:orange;
}

.has-return-reason{
    background:#ff4500b8;
    color: white;
}