.part_container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 230px);
}

.part_mainSection {
    width: 80%;
    display: flex;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.bgGreen {
    background-color: #3be36e;
}
.bgRed {
    background-color: #ec9888;
}
.bgBlue {
    background-color: #93d6f6;
}
.bgYellow {
    background-color: #f6f06e;
}

.rb {
    border-right: '1px solid';
}
