.all-documents {
    border: 1px solid lightgray;
    border-radius: 4px;
    font-size: 13px;
    font-style: italic;

    .all-documents-header,
    .all-documents-body {
        width: 100%;
        border-collapse: collapse;
        font-size: 90%;

        tr {
            height: 30px;

            &.header {
                background-color: lightgray;
            }

            &.row {
                background-color: #d3d3d387;
            }

            th,
            td {
                padding: 0px 5px;
                text-align: left;

                &.time-type {
                    width: 120px;
                }

                &.created-by {
                    width: 140px;
                }

                &.actions {
                    width: 110px;

                    .icon-button-group {
                        > button {
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }
    img {
        -webkit-user-drag: none;
        -webkit-user-drag: none;

        &.deg-0 {
            width: 100%;
        }

        &.deg-90 {
            height: 100%;
            transform: rotate(-90deg);
        }

        &.deg-180 {
            width: 100%;
            transform: rotate(-180deg);
        }

        &.deg-270 {
            height: 100%;
            transform: rotate(-270deg);
        }

        &.sample-doc {
            text-align: center;
            margin-top: 100px;
            width: 250px;
        }
    }

    embed {
        width: 100%;
        height: 100%;
    }

    .body-table-container {
        max-height: 500px;
        min-height: 100px;
    }

    .right-doc-sec {
        position: relative;
        .doc-page-move {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 99;

            & > span {
                background: #f50057;
                opacity: 0.3;
                color: white;
                height: 24px;
                width: 24px;

                &:hover {
                    opacity: 1;
                }
            }

            &.go-prev {
                left: 5px;
            }
            &.go-next {
                right: 5px;
            }
        }

        .actionButton-Container {
            margin-left: 10px;

            .historydoclist {
                &.MuiButton-outlined {
                    padding: 0px 2px !important;
                }
                &.MuiButton-root {
                    min-width: 30px !important;
                    padding: 4px 4px !important;
                }
            }
        }
        .document-close-action {
            position: absolute;
            right: 5px;
            display: grid;
            z-index: 99;
            & > span {
                background: #f50057;
                opacity: 0.5;
                color: white;
                height: 24px;
                width: 24px;

                & + span {
                    margin-top: 2px;
                }

                &:hover {
                    //background: #f50057;
                    opacity: 1;
                }
            }
        }
    }
}
