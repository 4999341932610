.chips {
    display: "flex";
    flex-wrap: wrap;
}
.chip {
    margin: 2;
}
.formControl {
    margin: 2;
    min-width: 120;
    max-width: 300;
}
.select_class {
    padding-left: 20px !important;
}
.setDialogWidth {
    min-height: 627px !important ;
    max-height: 627px !important;
}
.signatureCanvas {
    border: 1px solid black;
    width: 100%;
    height: auto;
    margin-top: 9px;
}
.setDialogWidthTech {
    min-height: 750px !important ;
    max-height: 750px !important;
    max-width: 100% !important;
}
.heading {
    margin-top: 40px !important;
    padding: 25px;
    padding-left: 26px;
}
.gridcontainer {
    margin-top: 27px !important;
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: block;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.15);
}

.setDialogFP {
    min-height: 180px !important ;
    max-height: 180px !important;
    max-width: 100% !important;
}
.setReset {
    display: block;
    align-items: center;
    left: 30% !important;
    margin-top: 10px !important;
}
.Submitbtn {
    width: 100% !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
}
.dialogActions {
    padding-left: 23px !important;
    padding-right: 23px !important;
}
.setDialogPartReq {
    min-height: 450px !important ;
    max-height: 180px !important;
    max-width: 100% !important;
    width: 700px !important;
}
.marginTop {
    margin-top: 25px !important;
}
.marginBottom {
    margin-bottom: 8px !important;
}
.mandatoryfields {
color:red !important;
float: left !important;
}
.MuiFormLabel-root {float: left;}
.mleft{
    margin-left: -107px !important;
}
