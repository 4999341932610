.assign-auditor-to-job{    
    .MuiPaper-root{
        width:600px;        
    }
    .filter-aud{
        position:relative;
        & > div {
            z-index: 1;
        }
        & > label {
            position: absolute;
            right: 10px;
            top: 15px;
            color: lightgrey;
            z-index: 0;
        }
    }
    .auditor-search{
        border: 1px solid lightgray;
        border-radius: 4px;
        font-size: 13px;
        font-style: italic;
    

        .auditor-search-header,
        .auditor-search-body {
            width:100%;
            border-collapse: collapse;

            tr{
                height: 30px;  
                
                &.header{                      
                    background-color: lightgray;
                }
                
                &.is-selected-auditor{
                    background-color:#23ef2350;
                }
                
                th, td{
                    padding: 5px;
                    text-align: left;                    
                   
                    &.is-selected{
                        width:30px;
                        span{
                            padding:0px;
                        }
                    }
                    &.ph-no, &.name{
                        width:150px;
                    }
                }
            }
        }    

        .body-table-container {
            max-height: calc(100vh - 280px);
            min-height: 100px;        
        }
    }
    .sending-loader{
        position: absolute;
    }
}