.maninDiv {
    // display: 'flex',
    background: white;
    padding: 20px;
    cursor: pointer;
    // transition: all ease 1s;
    // // height: 100,
    // justifyContent: 'center',
    // alignItem: 'center'
    .iconStyle {
        font-size: 40px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .iconfont {
        font-size: 18px;
        font-weight: 500;
        white-space: nowrap;
    }
    .iconfontLong {
        font-size: 15px;
        font-weight: 500;
        white-space: nowrap;
    }
}

.maninDiv:hover {
    background-color: grey;

    .iconStyle {
        color: white;
    }
    .iconfont {
        color: white;
    }
    .iconfontLong {
        color: white;
    }
}
