.tech-btn-group {
    margin-bottom: 3px;
    margin-top: -4px;
    .active-btn {
        box-shadow: '0px 0px rgba(0, 0, 0, 0.4)';
        margin: 3px;
        background: #f3296f !important;
        color: white;
        border-bottom: 3px solid #f3296f !important;
    }
    .all-btn {
        box-shadow: '0px 0px rgba(0, 0, 0, 0.4)';
        margin: 3px;
        border-bottom: 3px solid #50c03a !important;
        background: #50c03a !important;
        color: white;
    }
    .MuiButton-root {
        padding: 6px 16px 3px 16px;
    }
}

.add-btn-container {
    .MuiButton-root {
        padding: 0;
        margin: 0px 0px 7px 0px;
    }
}

.grid-info-icon {
    cursor: pointer;
    float: right;
    // margin-right: 45px;
    margin-top: 3px;
    color: #f50057;
    @media (max-width: 900px) {
        margin-right: 5px;
    }
}

.workflow-buttons {
    padding-bottom: 2px;
    & .MuiButton-root {
        min-width: auto !important;
    }
    & > button + button {
        margin-left: 10px;
    }
}
