.servicelableFont {
    font-size: 13px !important;
    margin-bottom: 10 !important;
}
.IdlelableFont {
    font-size: 10px !important;
    margin-bottom: 10 !important;
}
.valueFont {
    font-size: 23px !important;
    font-weight: 600 !important;
}
.partValueFont {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-top: 10px;
    // margin-bottom: 10px;
}
.mainLabel {
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: left;
    margin-bottom: -10px;
    margin-left: 5px;
}

.Cursor {
    cursor: pointer;
}
.CursorHov {
    cursor: pointer;
}
.CursorHov:hover {
    background: lightgray;
    color: white;
}

.valueFontStock {
    font-size: 17px !important;
    font-weight: 600 !important;
}

.HoursCard {
    width: 100%;
    margin: 1px;
    border: 1px solid white;
    min-width: 150px;
    padding: 5px 0px;
    background: white;
}
.graphCard {
    width: 100%;
    margin: 1px;
    border: 1px solid white;
    min-width: 150px;
    padding: 5px 0px;
    background: white;
}

.HoursCard:hover {
    background: lightgray;
    border: 1px solid lightgray;
    color: black;
}
