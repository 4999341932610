// @import '../_Basic/scss-var';
// @import '../_Basic/mixin';
@import '../../Root/mixin';
@import '../../Root/scss-var';

.validation-text {
    color: $gray-500;
    font-size: 70%;
    position: relative;
    bottom: 1px;
    left: 5px;
}

.req-field-msgs-popup {
    max-width: 400px !important;
    font-size: 80%;

    @include sm {
        font-size: 100%;
    }

    .req-field-msgs {
        list-style-type: disc !important;
        margin-left: 20px;
        color: red;
    }
}

.li-damage-faces {
    .veh-faces {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;

        .face-container {
            flex: 0.5 0;
            min-width: calc(50% - 4px);
            max-width: calc(50% - 4px);
            margin-bottom: 0px;
            position: relative;
            border: 1px solid gray;
            border-radius: 3px;
            margin: 1px;
            padding: 1px;

            // align child image in center when different size image rendering in one row.
            display: flex;
            justify-content: center;
            align-items: center;

            @include sm {
                min-width: calc(33.33% - 4px);
                max-width: calc(33.33% - 4px);
            }

            @include lg {
                min-width: calc(25% - 4px);
                max-width: calc(25% - 4px);
            }

            @include xl {
                min-width: calc(20% - 4px);
                max-width: calc(20% - 4px);
            }

            > img {
                cursor: pointer;
                max-width: 100%;
                max-height: 100%;
                height: auto;
                width: 100%;
            }

            span {
                position: absolute;
                right: 5px;
                top: 5px;
                border: 1px solid $gray-500;
                border-radius: 2px;
                padding: 0px 5px;
                cursor: pointer;

                &:hover {
                    border-color: $gray-700;
                }
            }
        }

        .popup {
            .drawer {
                position: relative;
            }
        }
    }
}

.li-autosuggest {
    .autosuggest-container {
        position: relative;

        .form-control + .loading-data {
            position: absolute;
            width: 35px;
            right: 15px;
            top: 12px;
        }

        .suggestion-container {
            position: absolute;
            background: white;
            height: auto;
            max-height: 200px;
            overflow: auto;
            z-index: 100;
            border: 1px solid gray;
            border-radius: 3px;
            white-space: nowrap;
            overflow-x: hidden;
            min-width: 300px;
            width: 300px;

            @include sm {
                max-height: 300px;
            }

            div {
                padding: 2px 5px;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;

                &.active {
                    background-color: gray;
                }

                &:hover {
                    background-color: gray;
                }
            }
        }
    }
}

.li-data-puller {
    .data-puller-container {
        position: relative;
        overflow: hidden;
        border-radius: 0.25rem;

        .form-control + .loading-data {
            position: absolute;
            width: 35px;
            right: 50px;
            top: 12px;
        }

        .data-puller-btn {
            position: absolute;
            right: 2px;
            bottom: 2px;
            font-size: 175%;
            transform-origin: right bottom;
            padding: 0px;
            font-weight: bold;
            transition: 0.3s;
            border: none;
            cursor: pointer;
            border-radius: 0.25rem;

            &:hover,
            &:active,
            &:focus {
                background: lightgrey;
                outline: none !important;
            }

            .search-icon {
                height: 26px;
                display: block;
                margin: 4px;
            }
        }
    }
}

.li-photo-uploader {
    .popup {
        .drawer {
            position: relative;
            max-height: calc(100vh - 135px);
            overflow: auto;
        }
    }

    .image-gallery {
        line-height: 0;
        min-height: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
        border-radius: 3px;
        border: 1px solid lightgrey;
        -webkit-column-count: 3;
        -webkit-column-gap: 0px;
        -moz-column-count: 3;
        -moz-column-gap: 0px;
        column-count: 2;
        column-gap: 0px;

        @include sm {
            column-count: 3;
        }

        @include lg {
            column-count: 4;
        }

        @include xl {
            column-count: 5;
        }

        > div {
            position: relative;

            .uploaded-images {
                cursor: pointer;
                width: calc(100% - 10px);
                margin: 5px;
                box-shadow: 0px 0px 5px 2px gray;

                + span {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    padding: 10px 5px 11px 5px;
                    border-radius: 2px;
                    transition: 0.2s;
                    cursor: pointer;
                    background-color: gray;
                    color: white;
                }
            }
        }
    }
}

.li-sign-pad {
    .sign-box {
        span {
            position: absolute;
            right: 7px;
            bottom: 50px;
            padding: 5px 10px;
            border-radius: 2px;
            transition: 0.2s;
            cursor: pointer;
            background-color: gray;
            color: white;
        }
    }
}

.btn.btn-size-prev-next {
    display: block;
    margin: auto;
    min-width: 260px;
    width: calc(100% - 20px);
}

.AutoSuggestDiv {
    background: rgba(0, 0, 0, 0.9);
    color: white;
    cursor: pointer;
    position: absolute;
    max-height: 300px;
    height: auto;
    overflow: auto;
    text-align: left;
    width: 100%;
    z-index: 1000;

    .SuggestUi {
        margin: 5px;
        font-weight: 500;
        font-size: 14px;
        border-bottom: 1px dotted white;
        transition: all ease 0.5s;
    }
    .SuggestUi:hover {
        background-color: white;
        color: black;
    }
}
