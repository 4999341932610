.reporting-notes-modal {
    .MuiPaper-root {
        width: 100%;
    }

    .notes-container {
        height: 400px;
        border: 1px solid lightgray;
        padding: 5px;
        border-radius: 4px;
        overflow-y: auto;
        font-style: italic;
        margin-bottom: 20px;

        .single-note {
            padding: 5px;

            .msg {
                font-size: 14px;
            }

            .footer {
                font-size: 12px;
                color: gray;
                text-align: right;
            }
        }

        .single-note + .single-note {
            border-top: 1px solid lightgray;
        }
    }
}
