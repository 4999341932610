.lableFont {
    font-size: 14px !important;
    margin-bottom: 10 !important;
}
.valueFont {
    font-size: 20px !important;
    font-weight: 600 !important;
}

.Cursor {
    cursor: pointer;
}
.CursorHov {
    cursor: pointer;
}
.CursorHov:hover {
    background: grey;
    color: white;
}

.valueFontStock {
    font-size: 17px !important;
    font-weight: 600 !important;
}

.orderCard {
    width: 100%;
    margin: 1px;
    border: 1px solid white;

    min-width: 250px;
    // background-color: red,
    // maxWidth: 185,
    padding: 5px 0px;
    background: white;
}

.orderCard:hover {
    background: grey;
    color: white;
}

.otherCards {
    width: '100%';
    margin: 1px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    // Opacity: 0.9,
    min-width: 120px;
    padding: 5px 0px;
    // backgroundColor: 'red'
}

@media screen and (max-width: 1632px) {
    .orderCard {
        width: 100%;
        margin: 1px;
        border: 1px solid white;
        min-width: 230px;
        padding: 5px 0px;
        background: white;
    }
    .valueFont {
        font-size: 15px !important;
        font-weight: 600 !important;
    }
}
@media screen and (max-width: 750px) {
    .valueFont {
        font-size: 13px !important;
        font-weight: 600 !important;
    }
}
