.report-screen-container {
    padding: 0px 10px;
    .MuiBreadcrumbs-ol {
        padding: 10px 0px 0px 0px !important;
        margin: 0px !important;
    }
}
.reporting-section {
    padding-left: 0px !important;
    .mainHeading {
        font-size: 25px;
        // font-weight: 500;
        color: #ec4e42;
        // padding-left: 10px;
        padding-bottom: 5px;
        text-transform: capitalize;
        text-align: left;
        // border: 1px solid rgba(0, 0, 0, 0.4);
    }

    .kpibox {
        // background-color: #f5f5f5;
        border-radius: 10px;
        // padding: 5px;
        //  min-height: 325px;
        //  max-height: 325px;

        // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
        .tableComp {
            background-color: white;
            border-radius: 10px;
            padding: 10px;
            min-height: 285px;
            max-height: 285px;

            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
        }
        .cardComp {
            background-color: white;
            border: 1px solid #f5f5f5;
            border-radius: 10px;
            padding: 10px;
            min-height: 250px;
            //   max-height: 147px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
        }
        .mr10 {
            margin-top: 10px;
        }
        .w100 {
            width: 100%;
        }
        .customChip {
            background: #abeaab;
            padding: 6px 10px;

            font-size: 11px;
            // width: 46px;
            font-weight: 700;
            color: #188f18;
            border-radius: 14px;
        }
        .customChipRed {
            background: #e58f87;
            padding: 6px 10px;
            font-size: 11px;
            // width: 46px;
            font-weight: 700;
            color: #792222;
            border-radius: 14px;
        }
    }

    .kpibox2 {
        // background-color: #f5f5f5;
        border-radius: 10px;
        .cardComp {
            background-color: white;
            border: 1px solid #f5f5f5;
            border-radius: 10px;
            padding: 10px;
            // min-height: 200px;
            //   max-height: 147px;
            // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
        }
        .mr10 {
            margin-top: 10px;
        }
        .w100 {
            width: 100%;
        }
        .customChip {
            background: #abeaab;
            padding: 6px 10px;
            font-size: 11px;
            // width: 46px;
            font-weight: 700;
            color: #188f18;
            border-radius: 14px;
        }
        .customChipRed {
            background: #e58f87;
            padding: 6px 10px;
            font-size: 11px;
            // width: 46px;
            font-weight: 700;
            color: #792222;
            border-radius: 14px;
        }
    }

    .kpibox3 {
        // background-color: #f5f5f5;
        border-radius: 10px;
        .cardComp {
            background-color: white;
            border: 1px solid #f5f5f5;
            border-radius: 10px;
            padding: 25px;
            // min-height: 200px;
            //   max-height: 147px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
        }
        .mr10 {
            margin-top: 10px;
        }
        .w100 {
            width: 100%;
        }
        .customChip {
            background: #abeaab;
            padding: 2px;
            font-size: 11px;
            // width: 46px;
            font-weight: 700;
            color: #188f18;
            border-radius: 14px;
        }
        .customChipRed {
            background: #e58f87;
            padding: 2px;
            font-size: 11px;
            // width: 46px;
            font-weight: 700;
            color: #792222;
            border-radius: 14px;
        }
    }
    .ReportingCard {
        // background-color: red;
        padding: 10px;
        background-color: #f5f5f5 !important;
        // font-family: Montserrat !important;
        border-radius: 10px;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;

        // .kpibox {
        //     background-color: white;
        //     border-radius: 10px;
        //     padding: 10px;
        //     min-height: 325px;
        //     max-height: 325px;

        //     // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
        // }
        // .headingOne {
        //     background-color: #f5f5f5;
        //     font-size: 18px;
        //     font-weight: bold;
        //     padding-left: 10px;
        //     margin-bottom: 10px;
        //     text-transform: capitalize;
        //     text-align: left;
        // }
        // .headingTwo {
        //     font-size: 18px;
        //     font-weight: 600;
        //     text-transform: capitalize;
        //     text-align: center;
        //     // margin-left: 5px;
        //     background-color: #f5f5f5;
        // }

        // .headingThree {
        //     font-size: 9px !important;
        //     // font-weight: 600;
        //     text-transform: capitalize;
        //     text-align: center;
        //     // margin-left: 5px;
        //     background-color: #f5f5f5;
        // }
    }
}
