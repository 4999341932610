.is-assigned {
    background: #4caf50;
    color: white;
}

.will-be-assigned {
    background: #dcfbdd;
}

.assign-tech-grid-container {
    height: calc(100vh - 200px);
    width: 770px;
    margin: 20px auto 20px auto;
}
