.mot-history-container {
    // padding: 20px;

    box-sizing: border-box;
    .text-dark {
        color: #343a40 !important;
    }
    .font-weight-normal {
        font-weight: 400 !important;
    }

    .text-center {
        text-align: center !important;
    }
    table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
        background-color: transparent;
        border-collapse: collapse;
        td,
        th {
            padding: 0.75rem;
            vertical-align: top;
            border-top: 1px solid #e9ecef;
        }
        .table-responsive {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }
        .w-100 {
            width: 100% !important;
        }
        .small {
            font-size: 80%;
            font-weight: 400;
        }
        .text-muted {
            color: #6c757d !important;
        }
        .text-uppercase {
            text-transform: uppercase !important;
        }
        .icon-container {
            padding-top: 2px;
            margin-right: 5px;
            .MuiSvgIcon-root {
                font-size: 18px !important;
            }
        }
    }
}
