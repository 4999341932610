.customeSign {
    .signatureClass {
        border: 1px solid black;
        width: 100%;
        height: 100%;
        // min-height: 200px !important;
        margin-top: 9px;
    }
    .existSignature {
        border: 1px solid black;
        width: 100%;
        height: 100%;
        margin-top: 14px;
        // min-height: 200px !important;
    }
}

@media screen and (max-width: 480px) {
    .signatureClass {
        height: 100px !important;
    }
    .existSignature {
        height: 100px !important;
    }
}
@media screen and (max-width: 820px) {
    .signatureClass {
        height: 150px !important;
    }
    .existSignature {
        height: 150px !important;
    }
}
