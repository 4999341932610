.salesDashboardContainer {
    background-color: #f5f5f5;
    .cardComp {
        background-color: white;
        border: 1px solid #f5f5f5;
        border-radius: 10px;
        padding: 10px;
        margin: 10px;
        // min-height: 200px;
        //   max-height: 147px;
        // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
    }
    .cardHeading {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 21px;
    }
    .newVehicleCardList {
        border-left: 5px solid #88eba4;
        padding: 5px 0px 0px 20px;
        width: 100%;
    }
}

.mainValue {
    // color: red;
    font-size: 21px;
    font-weight: bold;
}
.maintitle {
    font-size: 18px;
}

.mainValue {
    // color: red;
    font-size: 21px;
    font-weight: bold;
}
.maintitle {
    font-size: 18px;
}

@media only screen and (max-width: 600px) {
    .mainValue {
        font-size: 18px;
        font-weight: bold;
    }
    .maintitle {
        font-size: 15px;
    }
}
