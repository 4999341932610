.defect-screen {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    @media screen and (min-device-width: 400px) and (max-device-width: 1470px) { 
        width: 100%;
    }
    .sign-canvas {
        display: block;
       // margin: '0 auto';
        margin-top: 35px;
        border: 1px solid black;
        width: 100%;
        height: 100px;
    }
    .btn-defect {
        float: right !important;
        margin-top: 10px !important;
    }
    .sign-deletbtn {
        position: absolute;
        right: 0;
        top: 0;
    }
    .grp-btn{
        white-space: nowrap;
    }
    .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
        padding-top: '4.1px';
        padding-bottom: '4.1px'
    }
}
