.vehicle-detail {
    .vehicle-detail-body {
        .car-detail-column-left {
            width: 100%;
            padding-left: 0;
            .list-card {
                margin-bottom: 15px;
                border-radius: 6px;
                border: 1px solid #e0e0e0;
                overflow: hidden;
                background-color: #ffffff;
                .avatar {
                    width: 100%;
                    .image-card {
                        height: 510px;
                        img {
                            max-width: 100%;
                            height: 100%;
                            object-fit: contain;
                            display: block;
                            margin: auto;
                            position: relative;
                        }
                    }
                }
            }
        }
    }
    // .max-size {
    //     margin-bottom: 0px;
    //     .images-wrapper .images .image-card {
    //         width: 1280px;
    //         margin-bottom: 15px;
    //         .avtar {
    //             height: 720px;
    //         }
    //     }
    // }
}
