.BasicButtonGroup-Container {
    .ScrollArrow-Container {
        padding-top: 10px;
        height: 100%;
    }
    .btn-container {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        & .MuiButton-label {
            white-space: nowrap;
        }
    }
    .btn-container::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
}
