.screen {
    // margin: 10px 20px;

    .add_btn {
        position: absolute;
        top: 107px;
        right: 28px;
        border-radius: 2rem;
    }
}

.submit_btn {
    margin-bottom: 17px !important;
}

.mandatory-fields {
    .MuiInputLabel-animated {
        color: red !important;
    }
    .MuiFormHelperText-root {
        color: red;
    }
}
.gridadd-btn {
    overflow: hidden;
    .MuiButton-root {
        top: -1px !important;
        font-size: 0.7rem;
        text-transform: none;
        padding-top: 10.7px;
        display: inline-flex !important;
        padding-bottom: 10.7px;
    }
    .MuiButton-contained {
        box-shadow: none;
    }
}

.input-Number-remove-spinners {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    & input[type='number'] {
        -moz-appearance: textfield;
    }
}
