.budgetScreen {
    padding: 20px;
    // background-color: #f9f9f9;
    .bgrid {
        //  background-color: red;
    }
}

.budgetCard {
    width: 99%;
    // padding: 10px;
    // margin: 10;
    background-color: #f9f9f9;
    color: rgb(97, 97, 97);
    background-image: none;
    border-radius: 12px;
    border: 4px solid #f9f9f9;
    // max-width: 400px;
    min-width: 200px;
    min-height: 181px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .bheadingOne {
        font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
            'Segoe UI Emoji', 'Segoe UI Symbol';
        font-weight: 600;
    }
}

.meter_budgetCard {
    width: 99%;
    background-color: #f9f9f9;
    color: rgb(97, 97, 97);
    background-image: none;
    border-radius: 12px;
    border: 4px solid #f9f9f9;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .bheadingOne {
        font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
            'Segoe UI Emoji', 'Segoe UI Symbol';
        font-weight: 600;
    }
}
