.image-container {
    height: 800px;
    overflow-y: scroll;
    margin-right: -15px;
    text-align: center;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.dialog-section {
    @media (min-width: 1261px) {
        flex-grow: 1;
    }

    @media (max-width: 1260px) {
        flex: 1;
    }

    position: relative;

    .doc-page-move {
        position: absolute;
        z-index: 99;

        & > span {
            background: #f50057;
            opacity: 0.3;
            color: white;
            height: 24px;
            width: 24px;

            &:hover {
                opacity: 1;
            }
        }

        &.history {
            bottom: 5px;
            right: 20px;
        }

        &.go-prev {
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
        }

        &.go-next {
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
