.backgroundBlue {
    background-color: #29308c !important;
    color: white;
}
.headingText {
    line-height: 1px;
    padding: 10px;
}

.brw {
    border-right: 1px solid white;
}
.brb {
    border-right: 1px solid black;
}

.alignLeft {
    text-align: left;
}

.alignCenter {
    text-align: center;
}
.p10 {
    padding: 10px;
}
