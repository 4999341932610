// @import '../../Core/_Basic/scss-var';
// @import '../../Core/_Basic/mixin.scss';

@import '../../Core/Root/mixin';
@import '../../Core/Root/scss-var';

.enquiry-form {
    // background-color: red;
    height: 100%;
    position: relative;
    padding-bottom: 65px;

    .prev-next-container {
        text-align: center;
        position: fixed;
        width: calc(100vw - 40px);
        bottom: 38px;
        padding: 5px 0px;
        background: white;
        text-align: center;

        > .btn.btn-next,
        > .btn.btn-prev {
            width: calc(50% - 20px);
            min-width: 120px;
            margin: 0px 10px;

            &.disabled {
                background: $gray-300;
                border-color: $gray-500;
                color: $gray-500;
                cursor: not-allowed;
            }
        }
    }

    .footer {
        left: 0px;
        position: fixed;
        width: 100vw;
        bottom: 0px;

        .confirm-msg {
            margin: 15px 0px;
            color: red;
            border: 1px solid red;
            border-radius: 3px;
            padding: 15px;
            background-color: #fff0f0;
        }

        .steps {
            margin: 0px 2px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-evenly;

            .btn {
                flex: 0.25;
                padding-left: 2px;
                padding-right: 2px;

                &.btn-light {
                    background-color: lightgrey;
                    cursor: pointer;
                    padding: 10px;
                }

                &.btn-secondary {
                    background-color: grey;
                    cursor: pointer;
                    color: white;
                    padding: 10px;
                    > img {
                        filter: invert(0);
                    }
                }

                &.btn-light:hover {
                    background-color: rgb(219, 206, 206);
                }

                > img {
                    height: 15px !important;
                    transform: scale(2) !important;
                    top: 2px;
                    filter: invert(0.5);
                    position: relative;
                }

                > .step-label {
                    @include md {
                        display: inline !important;
                        margin-left: 14px;
                    }

                    &.auto-hide {
                        display: none;
                    }
                }
            }
        }
    }
}
