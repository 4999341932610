.report-screen-gijgo {
    .gj-grid {
        font-family: Montserrat !important;
        font-weight: 500 !important;
    }
    text-align: left;
    overflow-x: auto;
    TABLE {
        width: 100%;
        table-layout: fixed;
        max-width: 100%;
        border-collapse: collapse;
    }
    TABLE.gj-grid {
        margin: auto;
        border-collapse: collapse;
    }
    th {
        font-size: 14px;
        border: 1px solid #dee2e6;
        // padding: 0.75rem !important;
        text-align: left;
        word-wrap: break-word !important;
        vertical-align: middle !important;
        // white-space: none !important;
    }
    td {
        font-size: 14px;
        border: 1px solid #dee2e6;
        // padding: 0.75rem !important;
        text-align: left;
        word-wrap: break-word !important;
        vertical-align: top !important;
        // white-space: none !important;
    }

    TABLE.gj-grid THEAD TH {
        background-color: #f5f5f5 !important;
    }

    .gj-grid-md td:first-of-type,
    .gj-grid-md th:first-of-type {
        padding-left: 12px;
    }

    .gj-grid-md {
        white-space: break-spaces !important;
    }

    TABLE.gj-grid TH {
        text-overflow: unset !important;
        color: black;
        font-weight: bold;
    }
    .gj-grid-md thead tr[data-role='filter'] th {
        border-top: 1px solid #e0e0e0;
        padding: 0px 15px 0px 5px !important;
    }
    .gj-grid-md th {
        padding: 0px 10px !important;
        position: relative;
        box-sizing: border-box;
        height: 45px !important;
    }
    .gj-grid-md td {
        padding: 0px 5px 0px 5px !important;
        white-space: break-spaces !important;
        vertical-align: middle !important;
    }

    .grid-filter-select-th {
        padding: 10px 8px;
    }

    .grid-filter-select {
        width: 100%;
    }

    .gj-text-align-center {
        text-align: center;
    }

    table.gj-grid tbody div[data-role='display'] {
        white-space: normal;
    }

    .gj-grid tbody {
        // height: calc(100vh - 295px) !important;
        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .gj-grid tfoot [data-role='TotalAmount'] th {
        // padding: 4px 4px 10px 5px !important;
        // border: 1px solid #9e9e9e !important;
    }
    .gj-grid tfoot tr {
        // border: 1px solid #dcdcdc !important;
        // background-color: #f5f5f5;
    }
}

// .pr_SendTo {
//     .gj-grid-md td:first-of-type,
//     .gj-grid-md th:first-of-type {
//         width: 35px !important;
//         background-color: red;
//     }
// }
