.auditor-dashboard {
    .card {
        height: 200px;
        display: "flex";
        flex-direction: "column";
        color: white;
    }
    .cardMedia {
        padding-top: "56.25%"; // 16:9
    }
    .cardContent {
        flex-grow: 1;
        margin-top: 40px;
    }
    .cardGrid {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
.auditor-job-list {
    table.gj-grid tbody div[data-role="display"] {
        padding-left: 10px;
    }
    .gridHeader {
        font-size: 40px;
        font-weight: bold !important;
        background-color: azure;
    }
}

