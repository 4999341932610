.d-flex {
    display: flex !important;
}
.avatar {
    // width: 100%;
    // width: 900px;
    // min-width: 300px;
    // max-width: 700px;
    .image-card {
        height: 480px;
        max-width: 100%;
        overflow: hidden;
        img {
            max-width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
            margin: auto;
            position: relative;
        }
    }
    @media screen and (max-width: 550px) {
        .image-card {
            height: auto;
        }
    }
    .carousel-wrapper-class {
        position: relative;
        overflow: hidden;
        scroll-behavior: smooth;
        .other-images {
            padding: 6px;
            background-color: #2d2d2d;
            // justify-content: center;
            overflow-x: auto;
            min-height: 45px;
            /* Let's get this party started */
            &::-webkit-scrollbar {
                width: 1px;
                height: 1px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                -webkit-border-radius: 10px;
                border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                -webkit-border-radius: 10px;
                border-radius: 10px;
                background: #2d2d2d;
                // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
            }
            &::-webkit-scrollbar-thumb:window-inactive {
                background-color: #f44336;
            }
            .other-image {
                margin: 0 2px;
                width: 72px;
                height: 59px;
                img {
                    width: 72px;
                    height: 59px;
                    object-fit: cover;
                    cursor: pointer;
                    border: 2px solid #2d2d2d;
                    &.active {
                        border: 2px solid #ffffff;
                    }
                }
            }
            .no-photo {
                align-items: center;
                margin: auto;
                font-size: 15px;
                color: white;
            }
            .arrow {
                &.disable-next-prev {
                    .arrow-left,
                    .arrow-right {
                        cursor: not-allowed;
                        img {
                            filter: grayscale(1);
                        }
                    }
                }
                .arrow-left,
                .arrow-right {
                    position: absolute;
                    top: 50%;
                    // z-index: 999;
                    transform: translateY(-50%);
                    cursor: pointer;
                    // color: #ffffff;
                    img {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                    }
                }

                .arrow-left {
                    left: 10px;
                    color: #ffffff;
                }

                .arrow-right {
                    right: 10px;
                    color: #ffffff;
                }
            }
        }
    }
}

.dialog-container {
    .MuiDialogTitle-root {
        padding: 0px 24px !important;
    }
}
