.sign-pad-container {
  >div {
    position: relative;

    .sign-canvas {
      width: 100%;
      height: 300px;
      border: 1px dotted grey;
      touch-action: none;
      -ms-touch-action: none;
      -webkit-touch-action: none;

      &.resize-image {
        width: auto;
        height: auto;
      }
    }

    .readonly-wrapper {
      position: absolute;
      height: calc(100% - 42px);
      width: 100%;
      top: 0px;
      opacity: 0.15;
      background: #6c757d;
      cursor: not-allowed;
    }
  }
}