.authMain {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center !important;
    justify-content: center;
    // height: calc(100vh - 125px);
    // background-color: rgba(0, 0, 0, 0.01);
    .authModal {
        background-color: white;
        // width: 70%;
        // border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding: 40px 20px;

        .authDetails {
            display: flex;
            justify-content: center;
            align-items: center !important;
            justify-content: center;
            height: 600px;
            .text-left {
                text-align: left !important;
            }
        }

        // background-color: red;

        .notch {
            position: absolute;
            height: 7px;
            width: 28px;
            background-color: white;
            border-radius: 8px;
            margin-left: 160px;
            margin-top: 13px;
        }
        .buttons {
            position: absolute;
            /* background-color: red; */
            margin-left: 137px;
            color: white;
            margin-top: -26px;
        }

        .MobileView {
            display: flex;
            justify-content: center;
            align-items: center !important;
            justify-content: center;
            width: 280px;
            padding: 10px;
            border: 20px solid;
            height: 500px;
            border-radius: 14px;
            border-bottom: 35px solid;
            border-top: 35px solid;
            background-color: white;

            .qrScanner {
                height: auto;
                text-align: center;
                .Textbox {
                    margin-top: 20px;
                    border: 1px solid #1f3758;
                }
            }
        }
        .MobileView2 {
            display: flex;
            justify-content: center;
            align-items: center !important;
            justify-content: center;
            width: 280px;
            padding: 10px;
            border: 20px solid;
            height: 400px;
            border-radius: 14px;
            border-bottom: 35px solid;
            border-top: 35px solid;
            background-color: white;

            .qrScanner {
                height: auto;
                .Textbox {
                    margin-top: 20px;
                    border: 1px solid #1f3758;
                }
            }
        }
    }
}

.securityflow {
    font-size: auto;
    margin-top: 10px;
}

.icon {
    font-size: 50px !important;
}

.qrScanner {
    height: auto;
    text-align: center;
}

.subText {
    display: block;
    margin-top: 10px;
    font-size: 18px;
}

@media only screen and (max-width: 1024px) {
    .mainHeading {
        font-size: 21px;
    }

    .icon {
        font-size: 35px !important;
    }

    .authDetails {
        height: auto;
    }

    .subText {
        font-size: 10px;
        margin-top: 4px;
    }

    .securityflow {
        font-size: auto;
        margin-top: 0px;
    }

    .MobileView {
        width: auto;
        padding: 10px;
        border: none !important;
        height: auto !important;
        .qrScanner {
            height: auto;
            text-align: center;
            .Textbox {
                margin-top: 20px;
                border: 1px solid #1f3758;
            }
        }
    }

    .authModal {
        background-color: white;
        box-shadow: none !important;
        padding: 0px;
    }
}
@media only screen and (max-width: 633px) {
    .mainHeading {
        font-size: 16px !important;
        // color: blueviolet;
    }

    .icon {
        font-size: 25px !important;
    }

    .subText {
        display: none;
    }

    .authDetails {
        height: auto;
    }

    .MobileView {
        width: 100%;
        padding: 0px;
        border: none !important;
        height: auto !important;
        .qrScanner {
            height: auto;
            text-align: center;
            .Textbox {
                margin-top: 20px;
                border: 1px solid #1f3758;
            }
        }
    }

    .authModal {
        background-color: white;
        box-shadow: none !important;
        padding: 0px;
    }
}
