.speed-dial {
    .speed-dial-body {
        .MuiFab-root {
            width: 35px !important;
            height: 35px !important;
        }
        .MuiSvgIcon-root {
            font-size: 1.2rem !important;
        }
        .MuiSpeedDialIcon-root {
            height: 18px;
        }
        .MuiSpeedDialAction-staticTooltipLabel {
            width: max-content;
            // background-color: #f35f93;
            // color: aliceblue;
        }
        .MuiSpeedDialAction-fab {
            // background-color: #f50057;
            // color: aliceblue;
        }
    }
}
